'use strict';

// import "core-js/stable";
import SmoothScroll from './_SmoothScroll.js'
import ScrollHelper from './_ScrollHelper.js'  
import Intersection from './_Intersection.js'  
import ResizeHelper from './_ResizeHelper.js'
import ScrollDirectionClass from './_ScrollDirectionClass.js'
import ScrubScroll from './_ScrubScroll.js'
import ScrubScrollSP from './_ScrubScrollSP.js'
import HighlightAnchor from './_HighlightAnchor.js'
import Device from './_Device.js'
import ProgressCircle from './_ProgressCircle.js'
// import "regenerator-runtime/runtime";
import createjs from 'preload-js'


// import ScrollMagic from 'scrollmagic'
// import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo.js';

// import Swiper, { Navigation, Pagination, Autoplay, EffectCreative } from 'swiper';
// import '/node_modules/swiper/swiper-bundle.min.css';

// import Lenis from '@studio-freight/lenis'


const BREAK_POINT = 767
const isSP = window.matchMedia(`(max-width: ${BREAK_POINT}px)`)
// let i = 0
// setInterval(e => {
  
//   const r = 84
//   const c = Math.PI * r * 2
//   const rate = i * 0.01

//   document.querySelector('#loading_svg_arc').setAttribute('stroke-dasharray', [rate * c, ( 1 - rate ) * c])
//   i = (i+ 1) % 100
// }, 10)


const TOTAL_FRAMES = 121
const $background = document.querySelector('.js_background')
if($background ) {
  const kv_videoNative = document.querySelector('#kv_videoNative')

  const videoLoader = new createjs.LoadQueue();
  const progress = new ProgressCircle({
    el: '#loading_svg_arc'
  })
  videoLoader.addEventListener("progress", e => {
    progress.progress(e.progress)
  });
  videoLoader.addEventListener("complete", e => {
    
    document.body.classList.add('is-background-loaded')
    // dev(フレーム数表示)
    // if(location.search === '?dev') {
    //   const readout = document.createElement('div')
    //   readout.setAttribute('id', 'dev_frame')
    //   document.body.appendChild(readout)
    //   document.body.classList.add('--dev')
    // }
  
  });
  videoLoader.loadManifest([
    isSP.matches ? {src: "/assets/video/240318_toray_pc_fix.mp4"} : {src: "/assets/video/240318_toray_sp_fix.mp4"}
    // isSP.matches ? {src: "/assets/video/240215_kv_jyousyou.mp4"} : {src: "/assets/video/240215_kv_jyousyou.mp4"}
  ]);

  new Device({
    breakpoint: BREAK_POINT,
    pc: _ => {
      kv_videoNative.load()
    },
    sp: _ => {
      kv_videoNative.load()
    }
  })
  
  
  let duration
  kv_videoNative.addEventListener('loadedmetadata', e => {
    duration = kv_videoNative.duration
  });
  window.addEventListener('load', e => {
    kv_videoNative.play()
    kv_videoNative.pause()
    kv_videoNative.currentTime = 0

    new ScrubScroll(pos => {

      // console.log(document.scrollingElement.scrollTop)
      if(!isSP.matches) {
        kv_videoNative.currentTime = Math.min(pos * duration, duration)
  
        // dev
        const readout = document.getElementById('dev_frame')
        if(readout) {
          readout.innerText = Math.round(pos * TOTAL_FRAMES) + ' frame'
        }
      }
      
    })
    new ScrubScrollSP(pos => {

      if(isSP.matches) {
        kv_videoNative.currentTime = Math.min(pos * duration, duration) - 0.1
  
        // dev
        const readout = document.getElementById('dev_frame')
        if(readout) {
          readout.innerText = Math.round(pos * TOTAL_FRAMES) + ' frame'
        }
      }
      
    })

  })
  


}

const DESIGN_VIEWPORT = 375;
function vw(px){
  const _vw = (px/DESIGN_VIEWPORT) * window.innerWidth;
  return _vw;
}

class StickyAbout {
  constructor() {
    this.el = document.querySelector('.js_about-sticky')
    if(this.el) {
      this.setStyle()

      new ResizeHelper(() => {
        this.setStyle()
      })
    }
  }
  setStyle() {
    const positive = this.el.getBoundingClientRect().height - window.innerHeight
    this.el.style.top = positive * -1 + 'px'
  }
}



$(() => {
  document.body.classList.add('is-loaded')

  // set date
  const copyright = document.querySelector('#copyright')
  if(copyright) {
    copyright.innerText = (new Date()).getFullYear()
  }

  // const highlightanchor = document.querySelector('.js-highlightanchor')
  // const highlightanchoroffset = vw(0 /* dropdown sticky */)
  new HighlightAnchor()
  new ScrollDirectionClass()
  new StickyAbout()
  new SmoothScroll({
    // offset_product: {
    //   pc: 190 + 12,
    //   sp: vw(20)
    // },
    offset: {
      pc: 80,
      sp: vw(20)
      // sp: highlightanchor ? highlightanchoroffset + vw(134) + vw(80) : vw(134) + vw(80)
    }, 
    offset_immediate: {
      pc: 80,
      sp: document.body.classList.contains('has-sticky') ? vw(88 + 50) : vw(50)
    }
  })

  

  // SPメニュー
  const toggle = document.querySelector('.js-toggle')
  toggle.addEventListener('click', e => {
    document.body.classList.toggle('is-menu-active')
  })
  document.querySelectorAll('.js-dropdown a').forEach(el => {
    el.addEventListener('click', e => {
      document.body.classList.remove('is-menu-active')
    })
  })

  // const dropdown = document.querySelector('.js-dropdown')


  // /Product/stickyクラス
  const pagetitle = document.querySelector('.el_pagetitle');
  if(pagetitle) {
    const observer = new window.IntersectionObserver( (entry) => {
      if (!entry[0].isIntersecting) {
        document.body.classList.add('is-sticky');
      } else {
        document.body.classList.remove('is-sticky');
      }
    });
    observer.observe(pagetitle);
  }


  // /Product/左メニュー開閉
  const toggle_product_menu = document.querySelector('.js-toggle-productmenu')
  if(toggle_product_menu) {
    toggle_product_menu.addEventListener('click', e => {
      if(document.body.classList.contains('is-productmenu-active')) {
        document.body.classList.remove('is-productmenu-active')
        setTimeout(e => {
          document.body.classList.remove('is-productmenu-before-active')
        }, 160)
      } else {
        document.body.classList.add('is-productmenu-before-active')
        document.body.classList.add('is-productmenu-active')
      }
    })
  }

  // /Product/左メニュークリック時に閉じる
  document.querySelectorAll('.js-highlightanchor a').forEach(el => {
    el.addEventListener('click', e => {
      document.body.classList.remove('is-productmenu-active')
      document.body.classList.remove('is-productmenu-before-active')
    })
  })

  
  // const lenis = new Lenis({
  //   duration: .6,
  // })
  // function raf(time) {
  //   lenis.raf(time)
  //   requestAnimationFrame(raf)
  // }
  // requestAnimationFrame(raf)

  // $video.addEventListener('loadedmetadata', e => {
  //   console.log($video.duration, 'loadedmetadata', Date.now())
  // })
  // $video.addEventListener('canplaythrough', e => {
  //   console.log($video.duration, 'canplaythrough', Date.now())
  // })

  


  // kv > aboutエリア
  new Intersection('.js-kv-intersection', entries => {
    entries.forEach(entry => {
      document.body.classList.toggle('is-kv-invisible', !entry.isIntersecting)
    })
  })


  // inview
  new Intersection('.js-inview', entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-inview')
        // intersection.destroy()
      }
    })
  }, {
    root: null,
    rootMargin: "-10% 0px -10% 0px",
    threshold: 0
  })



  // scrolled class
  let windowHeight = window.innerHeight;
  let headerHeight = document.querySelector('.ly_scheader').getBoundingClientRect().height;
  new ResizeHelper(() => {
    windowHeight = window.innerHeight;
  })
  new ScrollHelper(function(){
    document.body.classList.toggle('is-scrolled', document.scrollingElement.scrollTop >= headerHeight);
  });

  // let visible = false
  // setInterval(e => {
  //   $('.js-inview').toggleClass('is-inview', visible)
  //   visible = !visible
  // }, 2500)

  
})
