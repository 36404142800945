// libNN.SmoothScroll2.js 2024/03/20
// 2024/03 immediate offset
// 2024/03 document.scrollingElement
// 2024/03 animejs

import anime from 'animejs/lib/anime.es.js'

const BREAK_POINT = 767;
const isSP = window.matchMedia(`(max-width: ${BREAK_POINT}px)`)
const DESIGN_VIEWPORT = 375;
const INVIEW_OFFSET = 30;


function vw(px){
  const _vw = (px/DESIGN_VIEWPORT) * window.innerWidth;
  return _vw;
}
class SmoothScroll{
  constructor(setting_arg){
    const config = {
      setonload: true,
      breakpoint: BREAK_POINT,
      offset: {
        pc: 0,
        sp: 0
      },
      offset_immediate: {
        pc: 0,
        sp: 0
      },
    }
    this.config = (Object.assign(config, setting_arg));
    this.callback_list = [];

    this.bindEvents();
    return;

  }
  bindEvents(){
    const hashList = document.querySelectorAll('a[href *= "#"]');
    const innerLinks = this.filter_otherPage(hashList);

    innerLinks.forEach(anchor => {
      anchor.addEventListener('click', this.onClick.bind(this))
    })

    if(this.config.setonload && location.hash !== ''){
      this.onload();
    }
  }
  filter_otherPage(hashlist){
    return Array.from(hashlist).filter((el, i, ary) => {
    const href = el.getAttribute('href');

    const url = new URL(href, location);
    const url_from = location.origin + location.pathname;
    const url_to = url.origin + url.pathname;

    return url_from === url_to;
    });
  }
  onClick(e){


    const href = e.currentTarget.getAttribute('href');
    const hash = '#' + href.split('#')[1];
    const targetElement = document.querySelector(hash);
    const targetPosition = this.calcPosition(targetElement);

    this.startScroll(targetPosition);
    e.preventDefault();
    e.stopPropagation();
  }
  calcOffset(){
    return isSP.matches ? vw(this.config.offset.sp) : this.config.offset.pc;
  }

  calcPosition(targetElement){
    const translate = targetElement.classList.contains('inview') && !targetElement.classList.contains('is-inview') ? INVIEW_OFFSET : 0;
    const offset = this.calcOffset() + translate;
    var to = targetElement ? document.scrollingElement.scrollTop + targetElement.getBoundingClientRect().top - offset : 0;
    return to;
  }

  startScroll(to, speed){
    this.begin();
    anime({
      targets: document.scrollingElement,
      scrollTop: to,
      duration: 500,
      easing: 'easeOutExpo',
    });
  }
  scrollImmediate(to){
    this.begin();
    const offset =  isSP.matches ? vw(this.config.offset_immediate.sp) : this.config.offset_immediate.pc;
    document.scrollingElement.scrollTop = to - offset
  }
  onload(){
    const target_to_scroll = document.querySelector(location.hash);
    if(target_to_scroll){
      const targetPosition = this.calcPosition(target_to_scroll);
      this.scrollImmediate(targetPosition);
    }
    window.addEventListener('load', e => {

      const target_to_scroll = document.querySelector(location.hash);
      if(target_to_scroll){
        const targetPosition = this.calcPosition(target_to_scroll);
        this.scrollImmediate(targetPosition);
      }
    })
    setTimeout(e => {
      const target_to_scroll = document.querySelector(location.hash);
      if(target_to_scroll){
        const targetPosition = this.calcPosition(target_to_scroll);
        this.scrollImmediate(targetPosition);
      }
    }, 20)
  }

  // calback placeholder
  begin(){
    return null;
  }
  complete(){
    return null;
  }

}

export default SmoothScroll;
