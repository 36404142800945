// import 'intersection-observer'

// v2023.03.22 add destroy()

/**
 * IntersectionObserverラッパー
 */
class Intersection {
  constructor( target, callback, options) {
    this.el = typeof target === 'string' ? document.querySelectorAll(target) : target
    this.callback = callback
    this.option = Object.assign({
      root: null,
      autoInit: true,
      rootMargin: "0px",
      threshold: 0.1
    }, options)

    if(this.option.autoInit) {
      this.init()
    }
  }
  init() {
    this.listen()
  }
  listen () {
    this.observer = new IntersectionObserver(this.callback, this.option)
    this.el.forEach(intersection => {
      this.observer.observe(intersection)
    })
  }
  destroy () {
    this.el.forEach(intersection => {
      this.observer.unobserve(intersection)
    })
  }
}

export default Intersection;
