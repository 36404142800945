// libNN.ScrollDirectionClass.js 2024/03
// 
import ScrollHelper from './_ScrollHelper.js'

class ScrollDirectionClass {
  constructor() {
    this.prev = 0
    new ScrollHelper(e => {
      document.querySelector('body').classList.toggle('is-scroll-up', this.prev > document.documentElement.scrollTop)
      this.prev = document.documentElement.scrollTop
    }, 1000 / 5)
  }
}

export default ScrollDirectionClass;

