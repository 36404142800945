
import ScrollHelper from './_ScrollHelper.js'  

import ResizeHelper from './_ResizeHelper.js'

const TOTAL_FRAMES = 121
class ScrubScroll {
  constructor(callback) {

    this.el = document.querySelectorAll('.js_scrubpos--pc')
    if(!this.el.length) return

    this.callback = callback
    this.keyframes = []
    this.marker = [55, 70, 90, 110, 141]
    // this.marker = [50, 50, 100, 140]
    this.survey()
    this.watch()
  }

  survey() {

    // keyframes初期化
    this.keyframes = []

    // 各位置取得
    this.el.forEach((el, index) => {
      const pos = el.getBoundingClientRect()
      const tmp = {
        "scroll": {
          "start": this.keyframes[index - 1] ? this.keyframes[index - 1].scroll.end : 0,
          "end": pos.top + document.scrollingElement.scrollTop - (window.innerHeight * 0.5) + (pos.height * 0.5),
          // "end": pos.top + document.scrollingElement.scrollTop - (window.innerHeight * 0.5) + (pos.height * 0.5), //vert center
        },
        "frame": {
          "start": this.keyframes[index - 1] ? this.keyframes[index - 1].frame.end : 0,
          "end": this.marker[index]
        }
      }
      this.keyframes.push(tmp)
    })

    // 最後の要素の高さを最終キーフレームに追加
    const scrubend = this.el[this.el.length - 1]
    this.keyframes.push({
      "scroll": {
        "start": this.keyframes[this.el.length - 1].scroll.end,
        "end": this.keyframes[this.el.length - 1].scroll.end + scrubend.getBoundingClientRect().height,
      },
      "frame": {
        "start": this.keyframes[this.el.length - 1].frame.end,
        "end": TOTAL_FRAMES
      }
    })

    // console.log(JSON.stringify(this.keyframes, null, 2))
  }


  calc(scrolltop) {
    const scene_index = this.keyframes.findIndex( (el, index) => el.scroll.end > scrolltop )
    if(scene_index === -1) {
      this.callback.call(this, 1)
    } else {

      const ratio = (scrolltop - this.keyframes[scene_index].scroll.start) /  (this.keyframes[scene_index].scroll.end - this.keyframes[scene_index].scroll.start)
      const currentframes = this.keyframes[scene_index].frame.end - this.keyframes[scene_index].frame.start
      const videoms = currentframes * ratio + this.keyframes[scene_index].frame.start
      this.callback.call(this, videoms / TOTAL_FRAMES)
      // console.log(scene_index, scrolltop, ratio, videoms)
      // document.body.style.opacity = ratio
    }

  }

  watch() {

    // window.addEventListener('load', e => {
    //   console.log('load')
    //   this.survey()
    // })
    new ResizeHelper(e => {
      this.survey()
    })
    // new ScrollHelper(e => {
    // //   // const scrolltop = document.scrollingElement.scrollTop
    // //   // this.calc(scrolltop)

    //   const scrolltop = document.scrollingElement.scrollTop
    //   this.calc(scrolltop)
    // }, 1000 / 60)

    window.addEventListener('scroll', e => {
      const scrolltop = document.scrollingElement.scrollTop
      this.calc(scrolltop)
    })
  }

}


export default ScrubScroll;
