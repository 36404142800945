// libNN.HighlightAnchor.js 2024/03

class HighlightAnchor {
  constructor() {
    this.$el = document.querySelector('.js-highlightanchor')

    if(this.$el) {

      this.store = [true]
      this.init()
    }

  }
  init() {

    const items = document.querySelectorAll('.js-highlightanchor-item')
    items.forEach((el, index) => {
      el.dataset.highlightanchorIndex = index
    })
    
    // const sticky_target = document.querySelectorAll('.js-anchor-position')
    const stickyObserver = new IntersectionObserver(e => {
      e.forEach(entry => {
        const current_index = entry.target.getAttribute('data-highlightanchor-index')
        this.store[current_index] = entry.isIntersecting
        this.setCurrent(this.store.findLastIndex(inview => inview))
      })
    }, {
      rootMargin: `0px 0px -60% 0px`,
      // rootMargin: `-40px`,
    })
    items.forEach(el => {
      stickyObserver.observe(el)
    })
  }

  setCurrent(index) {
    this.$el.dataset.current = index + 1
  } 


}


export default HighlightAnchor;
