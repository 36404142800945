// libNN.Device.js
// 2023/10 add callback option / use resizehelper
import ResizeHelper from './_ResizeHelper.js'


class Device{
  constructor(option, callback_pc, callback_sp){
    const defaults = Object.assign({
      breakpoint: 768
    }, option);
    this.breakpoint = defaults.breakpoint;
    this.observers = [];

    if(option.pc) {
      this.on('pc', option.pc)
    }
    if(option.sp) {
      this.on('sp', option.sp)
    }
    this.init()
  }
  init() {
    this.bind()
    this.resize()
  }
  bind() {
    // window.addEventListener('resize', this.resize.bind(this));
    window.addEventListener('load', this.resize.bind(this));
    new ResizeHelper(this.resize.bind(this))
    this.matchmedia = window.matchMedia(`(max-width: ${this.breakpoint}px)`)
  }
  resize(){
    this.is_sp = this.matchmedia.matches
  }
  set is_sp(sp){
    if(this._is_sp !== sp){
      this.emit(sp ? 'sp' : 'pc');
    }
    this._is_sp = sp;
  }
  get is_sp(){
    return this._is_sp;
  }
  emit(handle, ...args){
    if (handle in this.observers) {
      this.observers[handle].forEach(callback => callback(...args));
    }
  }
  on(handle, callback){
    if (!(handle in this.observers)) {
      this.observers[handle] = []
    }
    this.observers[handle].push(callback);
  }
}
export default Device;
