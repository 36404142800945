
import ScrollHelper from './_ScrollHelper.js'  

import ResizeHelper from './_ResizeHelper.js'
const TOTAL_FRAMES = 121
class ScrubScrollSP {
  constructor(callback) {

    this.el = document.querySelectorAll('.js_scrubpos--sp')
    if(!this.el.length) return

    this.callback = callback
    this.keyframes = []
    this.marker = [60, 60, 121]
    // this.marker = [50, 50, 100, 140]
    this.survey()
    this.watch()
  }

  survey() {
    const windowHeight = window.innerHeight
    // keyframes初期化
    this.keyframes = []

    // 各位置取得
    this.el.forEach((el, index) => {
      const tmp = {
        "scroll": {
          "start": this.keyframes[index - 1] ? this.keyframes[index - 1].scroll.end : 0,
          "end": el.getBoundingClientRect().top + document.scrollingElement.scrollTop - windowHeight,
        },
        "frame": {
          "start": this.keyframes[index - 1] ? this.keyframes[index - 1].frame.end : 0,
          "end": this.marker[index]
        }
      }
      this.keyframes.push(tmp)
    })

  }


  calc(scrolltop) {
    const scene_index = this.keyframes.findIndex( (el, index) => el.scroll.end > scrolltop )

    if(scene_index === -1) {
      this.callback.call(this, 1)
    } else {
      const start = this.keyframes[scene_index].scroll.start
      const end = this.keyframes[scene_index].scroll.end
      const ratio = (scrolltop - start) / (end - start)
      const frame_length = this.keyframes[scene_index].frame.end - this.keyframes[scene_index].frame.start
      const frame_number = frame_length * ratio + this.keyframes[scene_index].frame.start
      this.callback.call(this, frame_number / TOTAL_FRAMES)
    }

  }

  watch() {

    // window.addEventListener('load', e => {
    //   console.log('load')
    //   this.survey()
    // })
    new ResizeHelper(e => {
      this.survey()
    })
    new ScrollHelper(e => {
      const scrolltop = document.scrollingElement.scrollTop
      this.calc(scrolltop)
    }, 1000 / 120)

    // window.addEventListener('scroll', e => {
    //   const scrolltop = document.scrollingElement.scrollTop
    //   this.calc(scrolltop)
    // })
  }

}


export default ScrubScrollSP;
