// libNN.ProgressCircle.js 2024/03


class ProgressCircle {
  constructor(setting) {
    this.el = document.querySelector(setting.el)
  }
  progress(value) {
    const r = 84
    const c = Math.PI * r * 2
    this.el.setAttribute('stroke-dasharray', [value * c, ( 1 - value ) * c])

  }
}



export default ProgressCircle;
